<template>
  <div>
  <TosListVuetify entityName="nodi" :headers="headers">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Codice" v-model="slotProps.data.codice" />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:extraButtons>
        <v-btn color="primary" @click="onOpenSetNodoDefault" small v-if="isAdmin()"
          >Imposta nodo default</v-btn
        >
    </template>
    <template v-slot:item.codice="{ value }">{{ value }}</template>
    <template v-slot:item.isNodoTerminal="{ value }">{{ value  ? "NT" : "" }}</template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('codice') + '*'"  v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
            </v-col>
            <v-col cols="3">
              <v-select :label="tLabel('tipo_nodo') + '*'"  v-model="slotProps.data.tipoNodo" :items="tipoNodi" :rules="[rules.required]" :error-messages="slotProps.errors.tipoNodo" />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                :label="tLabel('locode') + '*'"
                v-model="slotProps.data.locode"
                :items="locodes"
                :rules="[rules.required]"
                :error-messages="slotProps.errors.locode"
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('descrizione') + '*'"  v-model="slotProps.data.descrizione" :rules="[rules.required]" :error-messages="slotProps.errors.descrizione" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('CAP')"  v-model="slotProps.data.cap"  :error-messages="slotProps.errors.cap" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('Indirizzo')" v-model="slotProps.data.indirizzo"  :error-messages="slotProps.errors.indirizzo" />
            </v-col>
            <v-col cols="3">
              <v-select :label="tLabel('gestore')" v-model.number="slotProps.data.gestoreId" item-text="label" item-value="id" :items="gestoriWithEmpty" :error-messages="slotProps.errors.gestoreId" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('codice_UIC')" v-model="slotProps.data.codiceUic" :error-messages="slotProps.errors.codiceUic" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('Latitudine')" v-model.number="slotProps.data.latitudine" :error-messages="slotProps.errors.latitudine" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('Longitudine')" v-model.number="slotProps.data.longitudine" :error-messages="slotProps.errors.longitudine" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('riferimento_esterno')" v-model.number="slotProps.data.riferimentoEsterno" :error-messages="slotProps.errors.riferimentoEsterno" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('nome_dir_entrata')" v-model.number="slotProps.data.nomeDirEntrata" :error-messages="slotProps.errors.nomeDirEntrata" />
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('nome_dir_uscita')" v-model.number="slotProps.data.nomeDirUscita" :error-messages="slotProps.errors.nomeDirUscita" />
            </v-col>
        </v-row>
      </div>
    </template>
  </TosListVuetify>

  <v-dialog v-model="showSetNodoDefault" persistent width="600">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select :label="tLabel('Nodo di default')" v-model.number="nodoDefaultId" :items="nodi"  />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="doSetNodoDefault" small>Imposta nodo default</v-btn>
          <v-btn @click="showSetNodoDefault = false" small
            >annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";
import toastMixin from '../../mixins/ToastMixin';

export default {
  name: "Nodi",
  data() {
    return {
      headers: [
         { text: '', value: 'action' },
         { text: '',  value: 'isNodoTerminal' },
         { text: 'Codice',  value: 'codice' },
         { text: 'Tipo nodo',  value: 'tipoNodoDesc' },
         { text: 'Locode',  value: 'locode' },
         { text: 'CAP',  value: 'cap' },
         { text: 'Indirizzo',  value: 'indirizzo' },
         { text: 'Gestore',  value: 'gestore' },
         { text: 'Codice UIC',  value: 'codiceUic' },
         { text: 'Nome Direzione Entrata',  value: 'nomeDirEntrata' },
         { text: 'Nome Direzione Uscita',  value: 'nomeDirUscita' }
      ],
      nodi:[],
      nodoDefaultId: null,
      tipoNodi: null,
      gestori: null,
      gestoriWithEmpty: null,
      locodes: null,
      showSetNodoDefault: false,
    };
  },
  service: null,
  created() {
  },
  async mounted(){
    this.nodi=(await this.$api.get(this.$apiConfiguration.BASE_PATH+"nodi/shortList")).data;
    this.locodes = await this.$api.get(this.$apiConfiguration.BASE_PATH+"nodi/locodes");
    this.tipoNodi = await this.$api.get(this.$apiConfiguration.BASE_PATH+"nodi/tipoNodi");
    this.gestori = await this.$api.get(this.$apiConfiguration.BASE_PATH+"soggetti/shortList");
    this.gestoriWithEmpty = [ {id: null, label: ""}, ...this.gestori.data ];
  },
  methods: {
    async onOpenSetNodoDefault(){
      this.nodi=await this.$api.get(this.$apiConfiguration.BASE_PATH+"nodi/shortListWithIsNodoDefault");
      this.nodi.filter(n=>n.isNodoDefault == true).forEach(n => this.nodoDefaultId=n.value );
      this.showSetNodoDefault=true;
    },
    async doSetNodoDefault(){
      try{
        await this.$api.post(this.$apiConfiguration.BASE_PATH+"nodi/setNodoDefault/"+this.nodoDefaultId);
        this.showSetNodoDefault=false;
        this.$emit("refreshTable");
        this.showSuccess('Nodo di default impostato correttamente');
      } catch(e){
        console.log("Errore doSetNodoDefault",e);
        this.showError('Si è verificato un errore');
      }
    },
    isAdmin(){
      const roles=this.$store.getters['authentication/loggedUser'].roles;
      if (roles) {
        return roles.includes("ADMINISTRATOR");
      }
      return false;
    },
  },
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins, toastMixin],
};
</script>
